import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Modal,
  TabContent,
  TabPane,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  FormFeedback,
  ListGroup,
  ListGroupItem,
  FormText,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Tooltip,
  Col,
  Row,
} from "reactstrap";
import useSWR from "swr";
import classNames from "classnames";

import "yet-another-react-lightbox/plugins/thumbnails.css";
import loader from "assets/images/icons/loader.svg";
import { saveAs } from "file-saver";

import CustomModal from "components/Modal/CustomModal";
import InquiryModal from "components/Modal/InquiryModal";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

import { GET_OPEN_IMAGE_LIKES } from "helpers/jiraya_url";
import { getOpenImageLike } from "helpers/jiraya_helper";
import { postOpenImageLike } from "helpers/jiraya_helper";
import { deleteOpenImageLike } from "helpers/jiraya_helper";

import GaleryComment from "./gallery_comment";

import CollectionGallery from "./collection_gallery";
import LikeGallery from "./liked_gallery";
// const isAndro = /Android/i.test(navigator.userAgent);

import { getCloudflareUrl } from "../../../../gallery_helpers/cloudflare_image";
import { ACTIVITY, GALLERY_ACCESS_LEVEL, GALLERY_PRIVACY } from "../enums";
import Loader from "components/Loader";
import { Item, Menu, useContextMenu } from "react-contexify";
import GalleryContext from "store/galleryContext";
import { getEventCustomisations } from "../Forms/customisation";
import { usePeopleTab } from "./lightbox/people_tab";
import useFeatureRestriction from "../useFeatureRestriction";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./language_selector";
import EventList from "../Organisation/EventList";
import FreePlanLimitModal from "../free_plan_limits";
import UserContext from "store/guest_user/userContext";
import MyUploadModal from "./my_uploads";
import UploadFloatButton from "../Upload/UploadNotifyModal";
import { UploadNotify } from "../Upload/UploadNotifyModal";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
export const COLLECTION_TYPE = { GENERAL: 4, PEOPLE: 5, HIGHLIGHT: 7, USER: 3 };
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const ALL_COLLECTION = -1;
const LIKE_COLLECTION = -2;
const COMMENT_COLLECTION = -3;
const GUEST_UPLOAD_COLLECTION = -4;

const DownloadOption = ({ isOpen, onCancel, collectionData, onDownload }) => {
  const [loading, setLoading] = useState(false);
  const [downloadCollection, setDownloadCollection] = useState({
    id: ALL_COLLECTION,
  });

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={onCancel}
      centered
      header={"Select the collection to Download"}
      className="gallery-modal"
    >
      <>
        {loading ? (
          <div
            className="h-100 w-100 d-flex justify-content-center"
            style={{ minHeight: "200px" }}
          >
            <img src={loader} />
          </div>
        ) : (
          <div>
            <FormGroup>
              <ListGroup>
                {collectionData.map((item) => (
                  <ListGroupItem
                    key={item.id}
                    onClick={() => setDownloadCollection(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <Input
                        name="collection"
                        type="radio"
                        checked={item.id === downloadCollection.id}
                        onChange={() => setDownloadCollection(item)}
                      />
                      <div className="flex-grow-1">{item.name}</div>
                      {/* <div className="pill rounded-pill">
                        <i className="mdi mdi-image-multiple me-2"></i>
                        {item.image_count}
                      </div>
                      <div className="pill rounded-pill">
                        <i className="mdi mdi-play-box-multiple me-2"></i>
                        {item.video_count}
                      </div> */}
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </FormGroup>
          </div>
        )}
      </>
      {!loading && (
        <>
          <div className="d-flex gap-3 mt-3 justify-content-end custom-modal-footer">
            <button className="btn btn-soft" onClick={onCancel}>
              Cancel
            </button>
            <button
              className="btn btn-gallery-primary"
              onClick={() =>
                onDownload(
                  downloadCollection.images,
                  downloadCollection.name,
                  downloadCollection.id
                )
              }
            >
              Download
            </button>
          </div>
        </>
      )}
    </CustomModal>
  );
};

const ClientGallery = ({
  isPublished,
  galleryRef,
  eventId,
  event,
  collectionData,
  eventKey,
  eventKeyType,
  request_id,
  request_key,
  activeCollection,
  onOpenFaceSearch,
  galleryProps,
  fetcher,
  fetcherUrl,
  reloadEvent,
  showAllImages,
  setShowAllImages,
  handleToggleShowAllImages,
  albumSelection,
  handleChangeAlbumSelection,
  allowLoadMore,
  orderBy,
  orderAsc,
  isPortfolio,
  access_key,
  teamView,
  orgId,
  filterText,
  handleOnChangeEventId,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
  handleOnOpenMyUploads,
  toggleMyUploads,
  isOpenMyUploads,
  playerGalleryProps,
}) => {
  const { user, handleLogoutUser } = useContext(UserContext);
  const { handleActivity } = useContext(GalleryContext);
  const [collection, setCollection] = useState(activeCollection);
  const { allowPeopleTab } = useFeatureRestriction({ event, eventKeyType });
  const { t } = useTranslation();

  let defaultActiveCollectionId = ALL_COLLECTION;
  if (albumSelection && collectionData?.length > 0) {
    defaultActiveCollectionId = collectionData[0].id;
  }

  const showLikeButton =
    eventKeyType === GALLERY_ACCESS_LEVEL.FULL && albumSelection;

  ////////////////////// reload all ////////////////////
  const handleChangeShowAll = () => {
    handleToggleShowAllImages();
    if (showAllImages) {
      collectionData.forEach((collection) => {
        if (collection.type === COLLECTION_TYPE.PEOPLE) {
          handleCollectionChange(collection.id);
        }
      });
    }

    // setShowAllImages(!showAllImages);
    // reloadImages();
    // setPushLiked((s) => s + 1);
    // mutate([GET_OPEN_IMAGE_LIKES, eventId, email]);
    // setCollection(-1);
  };
  const handleShowAll = () => {
    setShowAllImages(true);
    // reloadImages();
    // setPushLiked((s) => s + 1);
    // mutate([GET_OPEN_IMAGE_LIKES, eventId, email]);
  };

  // //// //// //// //// collection change
  const handleCollectionChange = (id) => {
    if (id == collection) return;

    setCollection(id);

    if (id == COMMENT_COLLECTION) {
      setImageInfoToComment(null);
      return;
    }
  };
  useEffect(() => {
    handleCollectionChange(activeCollection);
  }, [activeCollection]);

  ///////////////////////// FREE PLAN LIMITS ///////////////////
  const checkFreePlanLimit = (page, idx) => {
    if (
      (event?.is_restricted && page == 0 && idx > 8) ||
      (event?.is_restricted && page > 0)
    ) {
      setOpenDownloadLimitReached(true);
      return true;
    }
  };
  const checkImageLocked = (page, idx) => {
    if (
      (event?.is_restricted && page == 0 && idx > 8) ||
      (event?.is_restricted && page > 0)
    ) {
      return true;
    }
  };

  /////////////////////////////////////// DOWNLOAD////////////////////////////////////////////////////////////////////////////
  const [startDownload, setStartDownload] = useState(false);
  const [openDownloadOption, setOpenDownloadOption] = useState(false);
  const [openDownloadLimitReached, setOpenDownloadLimitReached] =
    useState(false);
  const toggleOpenDownloadLimitReached = () => {
    setOpenDownloadLimitReached((p) => !p);
  };

  const [collectionDownload, setCollectionDownload] = useState({
    files: [],
    name: "",
  });

  const [imageToolbarSubmitting, setImageToolbarSubmitting] = useState(false);

  const handleSaveImage = async (url, imgData) => {
    const res1 = await fetch(url);
    const blob1 = await res1.blob();
    try {
      if (isIOS) {
        const files = [
          new File([blob1], imgData.name, { type: imgData.mime_type }),
        ];
        await navigator.share({ files });
      } else {
        const blobNew = new Blob([blob1], { type: imgData.mime_type });
        saveAs(blobNew, imgData.name);
      }
    } catch (err) {
      if (err.name === "AbortError") {
        throw err;
      } else {
        saveAs(blob1, imgData.name);
      }
    }
  };
  const handleOnDownloadWithWatermark = async (imgData, eventData) => {
    try {
      if (eventData.is_archive) {
        const src = imgData.imageVersions.high.cf.src;
        await handleSaveImage(src, imgData);
      } else {
        const src = imgData.imageVersions.img.cf.src;
        await handleSaveImage(src, imgData);
      }
    } catch (err) {
      if (err.name === "AbortError") {
        throw err;
      }
      toast.error("Failed to download");
      console.log(err);
    }
  };
  const handleOnDownloadWithoutWatermark = async (imgData) => {
    try {
      const src = imgData.imageVersions.img.src;
      await handleSaveImage(src, imgData);
    } catch (err) {
      if (err.name === "AbortError") {
        throw err;
      }
      toast.error("Failed to download");
      console.log(err);
    }
  };

  const handleOnImageDownload = async (e, imgData) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      handleActivity(ACTIVITY.SINGLE_IMAGE_DOWNLOAD, imgData.id);
    } catch (err) {
      console.log(err);
    }

    try {
      if (
        (event.branding?.show_watermark &&
          event.branding?.watermark_on_download &&
          event.branding?.img_url) ||
        event.frame
      ) {
        await handleOnDownloadWithWatermark(imgData, event);
      } else {
        await handleOnDownloadWithoutWatermark(imgData);
      }
      // const res = await fetch(imgData.img_url);
      // const blob = await res.blob();
      // saveAs(blob, imgData.name);
    } catch (err) {
      if (err.name === "AbortError") {
        throw err;
      }
      toast.error("Failed to download");
      console.log(err);
    }
  };

  const handleOnDownload = () => {
    if (email === "") setOpenLogin(true);
    else if (email !== "" && request_id) {
      handleOnDownloadCollectionSelection();
    } else {
      setOpenDownloadOption(true);
    }
  };
  const handleOnDownloadCollectionSelection = (files, name, id) => {
    setOpenDownloadOption(false);
    setCollectionDownload({ files, name, id });
    if (checkFreePlanLimit(2, 2)) {
      setOpenDownloadLimitReached(true);
      return;
    }
    var downloadLink = `/download?event_id=${eventId}`;
    if (id) {
      downloadLink += `&collection_id=${id}`;
    }
    if (request_id) {
      downloadLink += `&request_id=${request_id}`;
    }
    if (request_key) {
      downloadLink += `&request_key=${request_key}`;
    }
    if (eventKey) {
      downloadLink += `&event_key=${eventKey}`;
    }
    const win = window.open(downloadLink, "_blank");
    win.focus();
    // }
  };

  /////////////////////////////////////// LOGIN ///////////////////////

  const [openLogin, setOpenLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [emailField, setEmailField] = useState("");
  const [loginError, setLoginError] = useState({ email: "" });

  useEffect(() => {
    const _email = localStorage.getItem("client-login");
    if (_email) setEmail(_email);
    if (albumSelection && !_email) {
      setOpenLogin(true);
    }
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onEmailChange = (e) => {
    setEmailField(e.target.value);
  };
  const onLogin = () => {
    if (!validateEmail(emailField)) {
      setLoginError({ email: "Invalid Email" });
    } else {
      setLoginError({ email: "" });
      localStorage.setItem("client-login", emailField);
      setEmail(emailField);
      setOpenLogin(false);
    }
  };

  const handleOnLogout = () => {
    handleLogoutUser();
    setEmail("");
    setLiked([]);
    localStorage.setItem("client-login", "");
    setCollection(defaultActiveCollectionId);
    if (albumSelection) {
      setOpenLogin(true);
    }
  };
  const LoginModal = () => (
    <Modal
      isOpen={openLogin}
      centered
      zIndex={12000}
      backdrop
      className="gallery-modal custom-modal"
    >
      <ModalHeader>{t("Enter Email ID to Continue")}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>{t("Email ID")} *</Label>
          <Input
            style={{ fontSize: "16px" }}
            invalid={loginError.email !== ""}
            type="email"
            onChange={onEmailChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") onLogin();
            }}
            value={emailField}
          ></Input>
          <FormFeedback>{loginError.email}</FormFeedback>
          <FormText>
            {t(
              "Make sure Email Id is correct, we will communicate with you using this Email ID"
            )}
          </FormText>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-gallery-primary" onClick={onLogin}>
          Login
        </button>
        {!albumSelection && (
          <button
            className="btn btn-primary-soft"
            onClick={() => setOpenLogin(false)}
          >
            Cancel
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
  ///////////////////////////////////////////////////// LIKE /////////////////////////////////////////////////////
  const getLikeKey = () => {
    if (!albumSelection) return null;
    return [GET_OPEN_IMAGE_LIKES, eventId, email];
  };
  const [pushLiked, setPushLiked] = useState(1);
  const {
    data: likedData,
    error: likedError,
    mutate: likedMutate,
  } = useSWR(getLikeKey(), () =>
    getOpenImageLike({
      params: {
        event_id: eventId,
        email_id: email,
        key: eventKey,
      },
    })
  );
  useEffect(() => {
    if (likedData && likedData.ok) {
      setLiked(likedData.data.map((item) => item.id));
    }
  }, [likedData, pushLiked]);
  const [liked, setLiked] = useState([]);
  const [acceptLikeWarning, setAcceptLikeWarning] = useState(false);
  const [imageInfoToComment, setImageInfoToComment] = useState(null);

  const updateLike = (photo) => {
    var likedImages = likedData;
    likedImages.data = [...likedImages.data];

    const newLiked = [...liked];
    const idx = newLiked.indexOf(photo.id);
    var action;
    if (idx !== -1) {
      newLiked.splice(idx, 1);
      likedImages.data.splice(idx, 1);
      action = "DELETE";
      if (newLiked.length == 0 && collection == LIKE_COLLECTION) {
        setCollection(defaultActiveCollectionId);
      }
    } else {
      if (
        event.max_like_count != -1 &&
        newLiked.length >= event.max_like_count
      ) {
        swal({
          title: "Limit Reached",
          icon: "warning",
          text: "You cannot select more photos. maximum selection limit reached. \n Contact gallery admin !!",
        });
      } else {
        newLiked.push(photo.id);
        likedImages.data.push(photo);
        action = "POST";
      }
    }
    setLiked(newLiked);

    const failCallback = (err) => {
      setLiked([...liked]);
      likedMutate(likedData);
      if (err && err.message === "Network Error") {
        toast.error("Please Check your internet connection");
      } else {
        toast("Failed to Like !! try after some time");
      }
    };
    if (action === "POST") {
      postOpenImageLike({
        event_id: eventId,
        email_id: email,
        image_id: photo.id,
        key: eventKey,
      })
        .then((response) => {
          if (response.ok) {
            likedMutate(likedImages);
          } else {
            failCallback();
          }
        })
        .catch((err) => {
          console.log(err);
          failCallback(err);
        });
    } else {
      deleteOpenImageLike({
        params: {
          event_id: eventId,
          email_id: email,
          image_id: photo.id,
          key: eventKey,
        },
      })
        .then((response) => {
          if (response.ok) {
            likedMutate(likedImages);
          } else {
            failCallback();
          }
        })
        .catch((err) => {
          console.log(err);
          failCallback(err);
        });
    }
  };
  const handleOnLike = (e, photo) => {
    e.preventDefault();
    e.stopPropagation();
    if (email === "") setOpenLogin(true);
    else {
      // if (liked.length === 0) {
      //   swal({
      //     title: "",
      //     text: "Liked images will be visible to the owner",
      //     buttons: true,
      //   }).then((ok) => {
      //     if (ok) {
      //       setAcceptLikeWarning(true);
      //       updateLike(photo);
      //     }
      //   });
      // } else {
      updateLike(photo);
      // }
    }
  };

  const checkLogin = () => {
    if (!email || email === "") {
      setOpenLogin(true);
    } else return true;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // var _isLoading = isLoading;
  const navbarRef = useRef();
  const prevScrollPos = useRef(0);
  // const [prevScrollPos, setPrevScrollPos] = useState(0);

  var stickyElem = null;
  var fillerElem = null;
  var currStickyPos = null;
  var prevScrollpos = React.createRef();
  var hideLevel = React.createRef();
  // var _isLoading = isLoading;
  useEffect(() => {
    if (isPublished) {
      stickyElem = document.querySelector(".client-gallery-nav-bar");
      fillerElem = document.querySelector(".client-gallery-nav-bar-filler");
      hideLevel.current = 1;
      prevScrollpos.current = window.pageYOffset;
      currStickyPos =
        stickyElem.getBoundingClientRect().top + window.pageYOffset;
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleScroll = (event) => {
    if (!stickyElem.classList.contains("sticky")) {
      currStickyPos =
        stickyElem.getBoundingClientRect().top + window.pageYOffset;
    }
    var currentScrollPos = window.pageYOffset;
    if (window.pageYOffset >= currStickyPos) {
      fillerElem.classList.remove("d-none");
      fillerElem.style.height = `${Math.floor(
        stickyElem.getBoundingClientRect().height
      )}px`;
      stickyElem.classList.add("sticky");
      if (
        prevScrollpos.current + 40 < currentScrollPos ||
        (prevScrollpos.current < currentScrollPos && hideLevel.current == 2)
      ) {
        stickyElem.style.top = `-${
          stickyElem.getBoundingClientRect().height + 10
        }px`;
        hideLevel.current = 2;
      } else if (prevScrollpos.current < currentScrollPos) {
        stickyElem.style.top = `-${
          document.getElementById("collection-nav").getBoundingClientRect()
            .height + 10
        }px`;
        // console.log("hide");
      } else {
        stickyElem.style.top = "0";
        // console.log("show");
        hideLevel.current = 1;
      }
    } else {
      stickyElem.classList.remove("sticky");
      fillerElem.classList.add("d-none");
      fillerElem.style.height = "0px";
      stickyElem.style = "";
    }
    prevScrollpos.current = currentScrollPos;
  };

  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const handleOnOpenFaceSearch = () => {
    setIsMessageShown(false);
    onOpenFaceSearch();
  };

  /////////////// Gallery Options
  const [isOpenLanguageSelector, setIsOpenLangauageSelector] = useState(false);
  const eventCustomisation = useMemo(() => getEventCustomisations(eventId));
  const galleryOptions = useCallback(() => {
    var options = [];
    if (event.publish_status === 3) {
      options.push(
        <>
          <div id="live-event" className="d-flex align-items-center gap-0 me-3">
            <i className="mdi mdi-circle font-size-10 align-middle me-1 live-dot"></i>
            LIVE
          </div>
          <UncontrolledTooltip target={"live-event"}>
            Refresh this page to see your photos
          </UncontrolledTooltip>
        </>
      );
    }
    if (!albumSelection && !isPortfolio) {
      options.push(
        <div
          id="FROption"
          onClick={handleOnOpenFaceSearch}
          style={{ cursor: "pointer" }}
        >
          {/* <div className="d-flex flex-row align-items-center gap-1"> */}
          <button
            className="btn btn-gallery-primary btn-face-search"
            style={{ width: "max-content" }}
          >
            <i className="align-middle face-recognition mdi mdi-face-recognition me-2" />
            {t("Face Search")}
            {/* <div className="d-block">Face Search</div> */}
          </button>
          {/* </div> */}
        </div>
      );
    }
    if (isPortfolio) {
      options.push(
        <button
          onClick={() => setShowInquiryModal(true)}
          className="btn btn-gallery-primary me-3 px-4"
          style={{ width: "max-content", cursor: "pointer" }}
        >
          <i className=" mdi mdi-information-outline align-middle  me-2" />
          {t("Inquiry")}
        </button>
      );
    }

    if (albumSelection && email && email !== "") {
      options.push(
        <>
          {liked.length > 0 ? (
            <button
              onClick={() => setCollection(LIKE_COLLECTION)}
              className="btn d-flex gap-1 align-items-center flex-grow-1"
            >
              <i className="mdi mdi-heart liked fs-4 align-middle" />
              {event.max_like_count != -1
                ? ` ${liked.length} / ${event.max_like_count}`
                : ` ${liked.length} ${t("Selected")}`}
            </button>
          ) : (
            <div className="btn d-flex gap-1 align-items-center flex-grow-1">
              <i className="mdi mdi-heart liked fs-4 align-middle" />
              {t("Select Images")}
            </div>
          )}
        </>
      );
    }
    return options;
  }, [liked, email, showAllImages, albumSelection]);

  const galleryOptionsMobileCollapse = useCallback(() => {
    var options = [];

    if (!albumSelection && eventCustomisation.inquiry && !isPortfolio) {
      options.push(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setShowInquiryModal(true)}
          className="d-flex gap-1 align-items-center inquiry"
        >
          {/* <div className="icon face-recognition" /> */}
          <i className=" mdi mdi-information-outline align-middle fs-4 me-3 me-md-0" />
          <div className="d-flex">{t("Inquiry")}</div>
        </div>
      );
    }

    if (
      event.allow_guest_download &&
      collection != COMMENT_COLLECTION &&
      !isPortfolio
    ) {
      options.push(
        <div
          style={{ cursor: "pointer" }}
          disabled={startDownload}
          onClick={handleOnDownload}
          className="d-flex gap-1 align-items-center "
        >
          <i className="mdi mdi-download fs-3 align-middle me-3 me-md-0" />
          <div className=" d-flex">{t("Download")}</div>
        </div>
      );
    }

    return options;
  }, [email, startDownload, showAllImages, collection]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);

  const galleryOptionsAlwaysCollapse = useCallback(() => {
    const options = [];
    if (request_id && eventKeyType === GALLERY_ACCESS_LEVEL.FULL) {
      options.push(
        <div
          className="d-flex gap-1 flex-row align-items-center"
          style={{ cursor: "pointer" }}
          onClick={handleChangeShowAll}
        >
          <i className="mdi mdi-folder-image fs-5 me-3" />
          {showAllImages ? (
            <div className="">{t("Show My")}</div>
          ) : (
            <div className="">{t("Show All")}</div>
          )}
        </div>
      );
    }

    if (eventKeyType === GALLERY_ACCESS_LEVEL.FULL && !isPortfolio) {
      if (albumSelection) {
        options.push(
          <div
            className="d-flex gap-1 flex-row align-items-center"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleChangeAlbumSelection(false);
            }}
          >
            <i className="mdi mdi-folder-image fs-5 me-3" />
            <div className="">{t("Gallery View")}</div>
          </div>
        );
      } else {
        options.push(
          <div
            className="d-flex gap-1 flex-row align-items-center"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              setCollection(-1);
              handleChangeAlbumSelection(true);
            }}
          >
            <i className="mdi mdi-account-heart fs-5 me-3" />
            <div className="">{t("Photo Selection")}</div>
          </div>
        );
      }
    }
    options.push(
      <div
        className="d-flex gap-1 flex-row align-items-center"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          setIsOpenLangauageSelector(true);
        }}
      >
        <i className="mdi mdi-translate fs-5 me-3" />
        <div className="">{t("Language")}</div>
      </div>
    );
    if (isGuestUploadAllowed && user) {
      options.push(
        <div
          className="d-flex gap-1 flex-row align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleOnOpenMyUploads()}
        >
          <i className="mdi mdi-upload fs-5 me-3" />
          <div className="">{t("My Uploads")}</div>
        </div>
      );
    }
    if (email || user) {
      options.push(
        <div
          className="d-flex gap-1 flex-row align-items-center"
          style={{ cursor: "pointer" }}
          onClick={handleOnLogout}
        >
          <i className="mdi mdi-logout fs-5 me-3" />
          <div className="">{t("Logout")}</div>
        </div>
      );
    }
    return options;
  }, [albumSelection, showAllImages, user, email]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleMobile = () => setMobileDropdownOpen((prevState) => !prevState);

  const { i18n } = useTranslation();
  const language = useMemo(() => {
    return i18n.language.split("-")[0];
  });

  const MobileOptions = useCallback(
    () => (
      <div className="d-block d-md-none gallery-options col-5 col-md-5 align-items-center justify-content-end flex-grow-1 d-flex ">
        {galleryOptions().map((item, idx) => (
          <div key={idx}>{item}</div>
        ))}
        {(galleryOptionsAlwaysCollapse().length > 0 ||
          galleryOptionsMobileCollapse().length > 0) && (
          <div className="d-flex justify-content-end gallery-dropdown">
            <Dropdown
              isOpen={mobileDropdownOpen}
              toggle={toggleMobile}
              direction={language === "ar" ? "end" : "start"}

              // className={classNames({ "gallery-options": isIOS })}
            >
              <DropdownToggle caret className="p-0 px-md-1 px-3">
                <i className="mdi mdi-menu fs-2 me-1" />
              </DropdownToggle>

              <DropdownMenu flip={false}>
                {galleryOptionsMobileCollapse().map((item, idx) => (
                  <div className="p-2" key={`mo-${idx}`}>
                    {item}
                  </div>
                ))}
                {galleryOptionsAlwaysCollapse().map((item, idx) => (
                  <div className="p-2" key={`al-${idx}`}>
                    {item}
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
    ),
    [
      toggle,
      galleryOptions,
      galleryOptionsAlwaysCollapse,
      galleryOptionsMobileCollapse,
    ]
  );
  const LaptopOptions = useCallback(
    () => (
      <>
        <div className="d-none d-md-flex col-5 col-md-5 align-items-center justify-content-end flex-grow-1  gap-2">
          {galleryOptions().map((item, idx) => (
            <div key={idx}>{item}</div>
          ))}
          {galleryOptionsMobileCollapse().map((item, idx) => (
            <div key={idx}>{item}</div>
          ))}
          {galleryOptionsAlwaysCollapse().length > 0 && (
            <div className="d-flex justify-content-end gallery-dropdown">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                direction={"left"}
              >
                <DropdownToggle caret>
                  <i className="mdi mdi-menu fs-2 me-1" />
                </DropdownToggle>
                <DropdownMenu flip={true}>
                  {galleryOptionsAlwaysCollapse().map((item, idx) => (
                    <div className="p-2" key={idx}>
                      {item}
                    </div>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      </>
    ),
    [
      toggle,
      galleryOptionsAlwaysCollapse,
      galleryOptions,
      galleryOptionsMobileCollapse,
    ]
  );

  const [isMessageShown, setIsMessageShown] = useState(false);

  //// people tab
  const peopleTabProps = usePeopleTab();

  ///////////
  return (
    <div ref={galleryRef} className="client-gallery">
      {/* <Menu id="navbar-options">
        {galleryOptionsMobileCollapse().map((item, idx) => (
          <Item key={Number(idx) * 10}>{item}</Item>
        ))}
        {galleryOptionsAlwaysCollapse().map((item, idx) => (
          <Item key={Number(idx) * 100}>{item}</Item>
        ))}
      </Menu> */}
      <MyUploadModal
        isPublished={isPublished}
        isOpenMyUploads={isOpenMyUploads}
        toggleMyUploads={toggleMyUploads}
        eventId={eventId}
        eventData={event}
        eventKey={eventKey}
        eventKeyType={eventKeyType}
        showAllImages={showAllImages}
        collection={-1}
        collectionId={-1}
        collectionData={collectionData}
        getImageParams={{
          fetcherUrl,
          fetcher,
          request_id: request_id,
          request_key,
          orderBy,
          orderAsc,
          access_key,
          team_view: teamView,
        }}
        galleryParams={{
          showLikeButton,
          liked,
          albumSelection,
          galleryProps,
          allow_guest_download: event.allow_guest_download,
          allow_guest_single_download: event.allow_guest_single_download,
          imageToolbarSubmitting,
          checkFreePlanLimit,
          checkImageLocked,
        }}
        galleryHandlers={{
          handleOnLike,
          handleOnImageDownload,
          handleOnOpenFaceSearch,
          handleShowAll,
        }}
        userData={{ email, checkLogin }}
        allowLoadMore={allowLoadMore}
        allowPeopleTab={allowPeopleTab}
        peopleTabProps={peopleTabProps}
      />

      <FreePlanLimitModal
        isOpen={openDownloadLimitReached}
        toggle={toggleOpenDownloadLimitReached}
      />

      {isPublished && (
        <>
          <InquiryModal
            setShowInquiryModal={setShowInquiryModal}
            isOpen={showInquiryModal}
            branding={event.branding}
            eventId={eventId}
            className="gallery-modal"
          />
          <UploadFloatButton
            isGuestUploadAllowed={isGuestUploadAllowed}
            handleOpenGuestUpload={handleOpenGuestUpload}
            galleryRef={galleryRef}
          />
          {collectionData?.length > 0 ? (
            <>
              <div
                ref={navbarRef}
                className={classNames("client-gallery-nav-bar px-md-4")}
              >
                <div className="d-flex">
                  <Nav
                    id="collection-nav"
                    className=" nav nav-tabs nav-tabs-custom flex-grow-1 justify-content-start collection-nav w-100"
                  >
                    {collectionData.map(
                      (item) =>
                        item.image_count +
                          item.video_count +
                          item.link_video_count !==
                          0 && (
                          <NavItem
                            key={item.id}
                            onClick={() => handleCollectionChange(item.id)}
                          >
                            <NavLink
                              href="#"
                              className={classNames({
                                active: item.id === collection,
                              })}
                            >
                              {t(item.name)}
                            </NavLink>
                          </NavItem>
                        )
                    )}
                    {liked.length > 0 && (
                      <NavItem
                        onClick={() => handleCollectionChange(LIKE_COLLECTION)}
                      >
                        <NavLink
                          className={classNames({
                            active: collection === LIKE_COLLECTION,
                          })}
                          href="#"
                        >
                          {t("Liked")}
                        </NavLink>
                      </NavItem>
                    )}
                    {albumSelection && (
                      <NavItem
                        onClick={() =>
                          handleCollectionChange(COMMENT_COLLECTION)
                        }
                      >
                        <NavLink
                          className={classNames({
                            active: collection === COMMENT_COLLECTION,
                          })}
                          href="#"
                        >
                          {t("Comments")}
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                </div>
                <div className="p-2">
                  <div className="row d-flex justify-content-md-start align-items-center flex-wrap justify-content-center">
                    {!albumSelection && (
                      <div className="flex-grow-1 col-5 col-md-5 brand-name me-3 text-start">
                        {teamView ? (
                          <EventList
                            eventId={eventId}
                            orgId={orgId}
                            handleOnChangeEventId={handleOnChangeEventId}
                            filterText={filterText}
                          />
                        ) : (
                          event.name
                        )}
                      </div>
                    )}

                    <MobileOptions />
                    <LaptopOptions />
                    <LanguageSelector
                      isOpen={isOpenLanguageSelector}
                      toggle={() =>
                        setIsOpenLangauageSelector(!isOpenLanguageSelector)
                      }
                    />
                  </div>
                </div>
                {peopleTabProps?.selectedPeople?.length > 0 && (
                  <div className="collection-people-tab">
                    {peopleTabProps.selectedPeople.map((item) => (
                      <div key={item.uuid_num} className="people-icon">
                        <img height={50} src={item.img_url} />
                        <div className="options">
                          <i
                            className="mdi mdi-close-circle fs-4"
                            onClick={() =>
                              peopleTabProps.handleRemoveSelectedPeople(item)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="client-gallery-nav-bar-filler d-none" />
              <TabContent
                className="gallery-grid-container"
                activeTab={collection.toString()}
                // activeTab={"my-uploads"}
              >
                <TabPane tabId={LIKE_COLLECTION.toString()}>
                  <LikeGallery
                    eventId={eventId}
                    imageData={likedData}
                    eventKey={eventKey}
                    collection={collection}
                    collectionData={collectionData}
                    getImageParams={{
                      fetcherUrl,
                      fetcher,
                      request_id,
                      request_key,
                      access_key,
                    }}
                    galleryParams={{
                      showLikeButton,
                      liked,
                      albumSelection,
                      galleryProps,
                      allow_guest_download: event.allow_guest_download,
                      imageToolbarSubmitting,
                    }}
                    galleryHandlers={{ handleOnLike, handleOnImageDownload }}
                    userData={{ email, checkLogin }}
                    checkImageLocked={checkImageLocked}
                  />
                </TabPane>

                {collection == COMMENT_COLLECTION ? (
                  <GaleryComment
                    eventId={eventId}
                    pass_key={eventKey}
                    email_id={email}
                    commentImgInfo={imageInfoToComment}
                    isAdmin={false}
                  />
                ) : (
                  collectionData.map((item) => (
                    <TabPane tabId={item.id.toString()} key={item.id}>
                      <CollectionGallery
                        eventId={eventId}
                        eventData={event}
                        eventKey={eventKey}
                        eventKeyType={eventKeyType}
                        showAllImages={showAllImages}
                        collection={collection}
                        collectionId={item.id}
                        collectionData={collectionData}
                        getImageParams={{
                          fetcherUrl,
                          fetcher,
                          request_id: request_id,
                          request_key,
                          orderBy,
                          orderAsc,
                          access_key,
                          team_view: teamView,
                          playerGalleryProps,
                        }}
                        galleryParams={{
                          showLikeButton,
                          liked,
                          albumSelection,
                          galleryProps,
                          allow_guest_download: event.allow_guest_download,
                          allow_guest_single_download:
                            event.allow_guest_single_download,
                          imageToolbarSubmitting,
                          checkFreePlanLimit,
                          checkImageLocked,
                        }}
                        galleryHandlers={{
                          handleOnLike,
                          handleOnImageDownload,
                          handleOnOpenFaceSearch,
                          handleShowAll,
                        }}
                        userData={{ email, checkLogin }}
                        allowLoadMore={allowLoadMore}
                        allowPeopleTab={allowPeopleTab}
                        peopleTabProps={peopleTabProps}
                      />
                    </TabPane>
                  ))
                )}
              </TabContent>
              {isGuestUploadAllowed && (
                <div className="p-3">
                  <Row className="justify-content-center">
                    <Col md={5} lg={3} sm={12}>
                      <UploadNotify
                        handleOpenGuestUpload={handleOpenGuestUpload}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {LoginModal()}
              {collection != COMMENT_COLLECTION && (
                <DownloadOption
                  requestId={showAllImages ? null : request_id}
                  isOpen={openDownloadOption}
                  collectionData={collectionData}
                  onDownload={handleOnDownloadCollectionSelection}
                  eventKey={eventKey}
                  eventId={eventId}
                  onCancel={() => {
                    setOpenDownloadOption(false);
                  }}
                  fetcher={fetcher}
                  {...collectionDownload}
                />
              )}
            </>
          ) : (
            <Loader className="ht-50" />
          )}
        </>
      )}
    </div>
  );
};

export default ClientGallery;
