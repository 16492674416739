import { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FaceSearchForm from "../Forms/face_search";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import getTitleCustomisationData from "./title_customisation";
import UploadNotifyModal from "../Upload/UploadNotifyModal";

const PreRegistration = ({
  galleryRef,
  eventDetails,
  passkey,
  accessKey,
  eventId,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
}) => {
  const { t } = useTranslation();
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    const alreadyRegisterd = localStorage.getItem(
      `event-registration:${eventId}`
    );
    if (alreadyRegisterd) {
      setRegistered(true);
      swal(
        t("You are Already Registed"),
        t("You will recieve an Email / WhatsApp as soon as the event is live"),
        "success"
      );
    }
  }, []);

  const handleOnResponse = async (response) => {
    console.log(response);
    if (response.ok) {
      setRegistered(true);
      localStorage.setItem(`event-registration:${eventId}`, true);
      // localStorage.setItem(`event-registration-data:${eventId}`, );
      swal(
        t("Registration Success"),
        t("You will recieve an Email / WhatsApp as soon as the event is live"),
        "success"
      );
    } else {
      swal(t("Registration Failed"), t("Contact the administrator"), "error");
    }
  };

  const { title, subtitle } = useMemo(
    () => getTitleCustomisationData(eventId),
    [eventId]
  );
  return (
    <div className="container mt-5" ref={galleryRef}>
      <Row className="justify-content-center gx-0">
        <Col md={6} sm={11}>
          {!registered ? (
            <div>
              <h2 className="text-center w-100">
                <i className="mdi mdi-check-circle text-success me-1" />{" "}
                {t("Register to Get Your Photos on your Mobile")}
              </h2>
              <Card className="pre-registration main-card  mt-4">
                <CardHeader className="position-relative">
                  {/* <div className="header-img">
                  <img src={headerBg} style={{ objectFit: "cover" }} />
                </div> */}

                  <div className="header">
                    <h4>{t(title)}</h4>
                    <div style={{ fontWeight: 300, fontSize: "1rem" }}>
                      <small>{t(subtitle)}</small>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="">
                    <FaceSearchForm
                      passkey={passkey}
                      accessKey={accessKey}
                      eventId={eventId}
                      submitButton={t("Register")}
                      onResponse={handleOnResponse}
                      isPreRegistration={true}
                      eventData={eventDetails}
                      isGuestUploadAllowed={isGuestUploadAllowed}
                      handleOpenGuestUpload={handleOpenGuestUpload}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          ) : (
            <Card className="pre-registration main-card">
              <CardHeader>
                <div className="text-center">
                  <h5>{t("Relax and Enjoy the Event")}</h5>
                  <div>
                    {t("We will notify you when your photos are ready")}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <lottie-player
                  src="https://lottie.host/38c2bc23-0dca-4c23-8302-7c2679fca3b7/VuR2lcXuZ2.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "auto", height: "400px" }}
                  className="animation"
                  rendererSettings={{ viewBoxSize: "500 0 2400 590" }}
                  loop
                  autoplay
                ></lottie-player>
                <div
                  onClick={() => setRegistered(false)}
                  className="text-center mt-2"
                  style={{ cursor: "pointer" }}
                >
                  {t("Click here to Register Again")}
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PreRegistration;
