// Authentication related pages
import GalleryPage from "../pages/Public/GalleryPage2";

// import Portfolio from "pages/Portfolio/portfolio";
// import PortfolioGalleryPage from "pages/Portfolio/PortfolioGalleryPage";
import DownloadPage from "pages/Public/DownloadPage";
import DownloadLinksPage from "pages/Public/DownloadLinksPage";
import DefaultPage from "pages/Public/DefaultPage";
import PublicGallery from "pages/Public/PublicGallery";
import NotFound from "pages/Errors/NotFound";
import SuspenseMiddleware, {
  componentLoader,
  LAZY_RETRY,
} from "./middleware/Suspensemiddleware";
import { lazy } from "react";
import Login from "pages/Authentication/Login";
import Spotlight from "pages/Spotlight/Spotlight";
import LinkedinAuthRedirect from "components/Events/Client/Spotlight/LinkedinAuthRedirect";
import Teams from "pages/Team/teams";

const GuestUploadPage = lazy(() =>
  componentLoader(() => import("pages/Public/GuestUploadPage"), LAZY_RETRY)
);

const openRoutes = [
  { path: "/login", component: Login },
  { path: "/404", component: NotFound },
  {
    path: "/:domainPattern/gallery/:accessKey/:verified/:accessLevel/:eventId",
    component: GalleryPage,
  },
  {
    path: "/gallery/:accessKey/:verified/:accessLevel/:eventId",
    component: GalleryPage,
  },
  { path: "/gallery/:eventId", component: GalleryPage },
  { path: "/:domainPattern/gallery/:eventId", component: GalleryPage },
  { path: "/download", component: DownloadPage },
  { path: "/:domainPattern/download-links", component: DownloadLinksPage },
  {
    path: "/:domainPattern/spotlight/:eventId",
    component: Spotlight,
  },
  { path: "/auth-linkedin", component: LinkedinAuthRedirect },
  { path: "/spotlight/:eventId", component: Spotlight },

  // Team Routes

  { path: "/teams/:eventId", component: Teams },
  {
    path: "/:domainPattern/teams/:eventId",
    component: Teams,
  },

  { path: "/download-links", component: DownloadLinksPage },
  { path: "/:domainPattern/portfolio/gallery", component: DefaultPage },
  { path: "/portfolio/gallery", component: DefaultPage },

  { path: "/", component: PublicGallery },
];

const authRoutes = [
  {
    path: "/guest/upload",
    component: () => <SuspenseMiddleware Component={GuestUploadPage} />,
  },
];
export { openRoutes, authRoutes };
